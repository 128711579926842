import React, { useRef } from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import { useRecoilState } from "recoil";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

import JournalEvents from "../components/JournalEvents";
import { fetchEventsCategories } from "../HTTPS/ApiService";
import { usePatient } from "../context/PatientContext";
import useEventManagement from "../hooks/useEventManagement";
import MessengerService from "../servies/MessengerService";
import MainAreaWrapper from "../layout/MainAreaWrapper";
import SearchBar from "../components/UI/SearchBar";
import { useUser } from "../context/UserContext";
import SendBarRevamp from "../components/Blocks/SendBarRevamp";
import ReuseableBlurModel from "../components/Modals/ReuseableBlurModel";
import EditActivityForm from "../components/Forms/EditActivityForm";
import AddActivityForm from "../components/Forms/AddActivityForm";
import useReactQuery from "../hooks/useReactQuery";
import { getCorrectDateTimeString, getDateFromDate } from "../utils/chartUtils";
import ObservationBlock from "../components/Blocks/ObservationBlock";
import { IconButton, Tooltip } from "@mui/material";
import QuickJournalingComp from "../components/Blocks/QuickJournalingComp";
import { activeTabState } from "../recoil/journel/activeTabSate";

const Journal = () => {
  const [eventLoading, setEventLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setEventLoading(false);
    }, 5000);

    // Clear the timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  const observationsRef = useRef(null); // Add the ref here
  const [active, setActive] = useRecoilState(activeTabState);

  const { data: categories } = useReactQuery(
    "EventsCategories",
    fetchEventsCategories,
  );

  const [quickJournalingOpen, setQuickJournalingOpen] = useState(false);
  const [createActivityOpen, setCreateActivityOpen] = useState(false);
  const [editActivityOpen, setEditActivityOpen] = useState(false);
  const [activityToBeEdited, setActivityToBeEdited] = useState({});
  const { patient } = usePatient();

  const toggleActive = (index) => {
    setActive(index);
  };

  const { user } = useUser();

  useEffect(() => {
    if (patient) {
      MessengerService.setSelectedPatient(patient.id);
      localStorage.setItem("selectedPatient", JSON.stringify(patient));
    }
  }, [patient]);

  const { event, events, deleteEventHandler, fetchEvents } =
    useEventManagement();

  const [searchTerm, setSearchTerm] = useState("");

  const [filterEventType, setFilterEventType] = useState("past");

  const today = moment();

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filterByDate = (item, itemDate, filterType) => {
    const momentItemDate = moment(
      getDateFromDate(itemDate),
      "YYYY-MM-DDTHH:mm:ss",
    );

    if (filterType === "today") {
      return momentItemDate.isSame(today, "day");
    } else if (filterType === "yesterday") {
      const yesterday = today.clone().subtract(1, "day");
      return momentItemDate.isSame(yesterday, "day");
    } else if (filterType === "past") {
      const tenDaysAgo = today.clone().subtract(10, "days");
      return momentItemDate.isAfter(tenDaysAgo);
    }
    return true; // Show all items if no filter is applied
  };

  const filterEventsByDateAndImp = (items, filterType) => {
    if (filterType === "isImp") {
      return items.filter((item) => item?.isImp === true);
    } else {
      return items.filter(
        (item) =>
          item.text.toLowerCase().includes(searchTerm.toLowerCase()) &&
          filterByDate(
            item,
            getCorrectDateTimeString(item.evDate, item.evTime),
            filterType,
          ),
      );
    }
  };

  const filteredEvents = filterEventsByDateAndImp(events, filterEventType);

  const handleEventFilterClick = (filterType) => {
    setFilterEventType(filterType);
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    setFilterEventType("all"); // Assuming "all" is the default value for the event filter
  };
  return (
    <MainAreaWrapper observationsRef={observationsRef}>
      <main className="bg-white mt-28 lg:mt-10 rounded-t-3xl pb-10 lg:pb-0 -z-0">
        {/* Message Bar  */}
        <div className="mx-auto p-4 md:p-6 2xl:p-10">
          <div className="mx-auto">
            {/* <!-- Breadcrumb Start --> */}
            <h2 className="text-3xl sm:text-5xl font-bold text-[#1D3E7D]">
              Journaling
              <Tooltip title="Quick Journaling">
                <IconButton
                  aria-label="delete"
                  sx={{ ml: 2 }}
                  onClick={() => setQuickJournalingOpen(true)}
                >
                  <CompareArrowsIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </h2>

            {/* Toggle Button  */}
            <div className="flex flex-row gap-3 sm:gap-5 justify-around items-center py-3">
              <button
                onClick={() => {
                  toggleActive(1);
                  setSearchTerm("");
                }}
                className={`font-bold text-[#1F456E] border-b-4 sm:border-b-8 border-transparent w-1/2 rounded-t-2xl py-2 sm:py-4 text-lg sm:text-2xl ${
                  active === 1 ? "!border-[#1F456E] bg-[#ECF1F4] px-2" : ""
                }`}
                setActive={true}
              >
                Observations
              </button>
              <button
                onClick={() => {
                  toggleActive(2);
                  setSearchTerm("");
                }}
                className={`font-bold text-[#1F456E] border-b-4 sm:border-b-8 border-transparent w-1/2 rounded-t-2xl py-2 sm:py-4 text-lg sm:text-2xl ${
                  active === 2 ? "!border-[#1F456E] bg-[#ECF1F4]" : ""
                }`}
              >
                Activities
              </button>
            </div>

            {/* Content - Notes and Events */}

            {active === 1 && <ObservationBlock />}

            {/* Events Tab  */}
            {active === 2 && (
              <>
                <div className="flex items-end justify-end space-y-3 xs:space-y-0">
                  <div className="flex items-center">
                    <SearchBar
                      searchTerm={searchTerm}
                      setSearchTerm={setSearchTerm}
                      handleSearch={handleSearch}
                      handleEventFilterClick={handleEventFilterClick}
                      handleClearFilter={handleClearFilter}
                      filterEventType={filterEventType}
                    />
                  </div>

                  <div className="flex sm:mb-0">
                    <button
                      onClick={() => setCreateActivityOpen(true)}
                      disabled={
                        user?.role !== "Kare Admin" &&
                        user?.role !== "Kare Giver"
                      }
                      className={` ${
                        user?.role !== "Kare Admin" &&
                        user?.role !== "Kare Giver"
                          ? "flex items-center gap-2 rounded-full bg-[#ED7117] opacity-30"
                          : "flex items-center gap-2 rounded-full bg-[#ED7117]"
                      }`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="52"
                        height="52"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M18 12.75H6c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h12c.41 0 .75.34.75.75s-.34.75-.75.75Z"
                          fill="#fff"
                        ></path>
                        <path
                          d="M12 18.75c-.41 0-.75-.34-.75-.75V6c0-.41.34-.75.75-.75s.75.34.75.75v12c0 .41-.34.75-.75.75Z"
                          fill="#fff"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                {eventLoading && <p className="p-5">Loading...</p>}
                {!eventLoading && filteredEvents.length === 0 ? (
                  <p className="text-[#ff0000] p-5">No Activities found.</p>
                ) : (
                  !eventLoading && (
                    <JournalEvents
                      key={event.id}
                      events={filteredEvents.sort((a, b) => {
                        // debugger
                        const timestampA = getCorrectDateTimeString(
                          a.evDate,
                          a.evTime,
                        );
                        const timestampB = getCorrectDateTimeString(
                          b.evDate,
                          b.evTime,
                        );
                        if (moment(timestampA).isSame(timestampB)) {
                          return a.id > b.id ? -1 : 1; // because seconds are not there so we are comparing SQL id
                        } else {
                          return moment(timestampA).isBefore(timestampB)
                            ? 1
                            : -1;
                        }
                      })}
                      setEditActivityOpen={setEditActivityOpen}
                      setActivityToBeEdited={setActivityToBeEdited}
                      onDelete={(eventId) => deleteEventHandler(eventId)}
                      fetchEvents={fetchEvents}
                      categories={categories}
                    />
                  )
                )}
              </>
            )}
          </div>
        </div>
      </main>

      {/* Send bar active only in journal tab  */}
      {active === 1 &&
        (user?.role === "Kare Admin" || user?.role === "Kare Giver") && (
          <SendBarRevamp observationsRef={observationsRef} />
        )}
      <ReuseableBlurModel
        open={createActivityOpen}
        setOpen={setCreateActivityOpen}
      >
        <AddActivityForm
          setOpen={setCreateActivityOpen}
          fetchEvents={fetchEvents}
        />
      </ReuseableBlurModel>
      <ReuseableBlurModel open={editActivityOpen} setOpen={setEditActivityOpen}>
        <EditActivityForm
          activity={activityToBeEdited}
          setOpen={setEditActivityOpen}
          fetchEvents={fetchEvents}
        />
      </ReuseableBlurModel>
      <ReuseableBlurModel
        open={quickJournalingOpen}
        setOpen={setQuickJournalingOpen}
        color="#000"
      >
        <div className="bg-white absolute right-0 bottom-0 rounded-xl py-4 px-2 h-screen mx-auto mt-10 w-full overflow-scroll">
          <QuickJournalingComp
            fetchEvents={fetchEvents}
            setQuickJournalingOpen={setQuickJournalingOpen}
          />
        </div>
      </ReuseableBlurModel>
    </MainAreaWrapper>
  );
};

export default Journal;
