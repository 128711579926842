import axios from "axios";
import { CachedUser } from "../utils/UserData";
import { refreshTokenEndpoint } from "../HTTPS/Api";
import {logout } from "../HTTPS/ApiService";

//TODO set base url from environment variable
const api = axios.create({ baseURL: "" });

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${CachedUser()?.accessToken}`;
    return config;
  },
  (error) => Promise.reject(error)
);

let refreshStarted = false;
let refreshDone = false;
let refreshCounter = false;
let newToken = '';

const resetRefreshFlags = () => {
  refreshStarted = false;
  refreshDone = false;
  newToken = "";
}

const generateRefreshToken = async () => {
  if (refreshStarted) return;
  refreshStarted = true;

  const url = `${refreshTokenEndpoint}?token=${encodeURIComponent(CachedUser()?.refreshToken)}`;
  const response = await axios.create({ baseURL: "" }).get(url);

  //divide by 1000 to convert to seconds
  const now = Math.floor(Date.now() / 1000);

  // Store user data in local storage
  localStorage.setItem(
    "user",
    JSON.stringify({
      username: response.data?.UserName,
      name: response.data?.Name,
      role: response.data?.Role,
      accessToken: response.data?.AccessToken,
      refreshToken: response.data?.RefreshToken,
      expiry: now + response.data?.Expires //Expires in seconds
    })
  );

  refreshDone = true;
  return response.data?.AccessToken;
}

// Add a response interceptor
api.interceptors.response.use(
  async (response) => {
    if (response?.status === 200) {
      return { isSuccess: true, data: response.data };
    } else {
      return { isSuccess: true, data: true };
    }
  },
  async (error) => {
    const originalRequest = error.config;

    // If the error status is 401 and there is no originalRequest._retry flag,
    // it means the token has expired and we need to refresh it
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {

        refreshCounter++;
        console.log('refreshCounter++', refreshCounter);

        const hasSignedIn = localStorage.getItem("hasSignedIn");
        if (hasSignedIn) {
          if (!refreshStarted) {
            newToken = await generateRefreshToken();
          }

          const promise1 = new Promise((resolve, reject) => {
            const loop = () => refreshDone ? resolve(refreshDone) : setTimeout(loop)
            loop();
          });

          promise1.then((value) => {

            refreshCounter--;
            console.log('refreshCounter--', refreshCounter);

            // Retry the original request with the new token
            originalRequest.headers.Authorization = `Bearer ${newToken}`;

            // eslint-disable-next-line eqeqeq
            if (refreshCounter == 0) {
              resetRefreshFlags();
            }
            return axios(originalRequest);
          });

        } else {
          resetRefreshFlags();
          // Handle refresh token error or redirect to login
          const loginInfo = JSON.parse(localStorage.getItem("user"));
          if (loginInfo) {
            logout(loginInfo.username);
          }

          localStorage.removeItem("hasSignedIn");
          localStorage.clear();

          console.log('window.location.pathname', window.location.pathname);
          if (window.location.pathname !== '/signin') {
            window.location.href = "/#/signin";
          }
        }
      } catch (error) {
        resetRefreshFlags();
        // Handle refresh token error or redirect to login

        const loginInfo = JSON.parse(localStorage.getItem("user"));
        if (loginInfo) {
          logout(loginInfo.username);
        }

        localStorage.removeItem("hasSignedIn");
        localStorage.clear();

        console.log('window.location.pathname', window.location.pathname);
        if (window.location.pathname !== '/signin') {
          window.location.href = "/#/signin";
        }
      }
    } else if (error.response?.status === 400) {
      return Promise.resolve({ isSuccess: false, errMsg: error.response.data });
    }

    return Promise.resolve(error);
  }
);

export default api;
