import React from 'react'
import { Modal } from '@mui/material';
import ClosePopupIcon from '../Icons/ClosePopupIcon';


function ReuseableBlurModel({children, open, setOpen, color = "#ffffff"}) {
  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className="bg-black/40 absolute inset-0 h-screen w-full backdrop-blur-md flex overflow-y-auto"
        >
        <div>
          <button
            className="absolute top-5 right-5 z-[999]"
            onClick={() => setOpen(false)}
          >
            <ClosePopupIcon color={color}/>
          </button>
          {children}
        </div>
      </Modal>
    </>
  )
}

export default ReuseableBlurModel