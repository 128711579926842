import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import moment from "moment";

import { usePatient } from "../../context/PatientContext";
import { listBloodPressureVital } from "../../HTTPS/VitalApis";
import VitalTableCard from "../UI/VitalTableCard";
import { formatDate, formatTime } from "../../utils/chartUtils";
import ReuseableModel from "../Modals/ReuseableModel";
import BpEditVitalForm from "../Forms/BpEditVitalForm";

function BloodPressureTable() {
  const [open, setOpen] = useState(false);
  const [entry, setEntry] = useState({});
  const { patient } = usePatient();

  const { data, isLoading } = useQuery({
    queryKey: ["BPVitalList", patient?.id],
    queryFn: () => listBloodPressureVital(patient?.providerUserId),
    enabled: !!patient?.id,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <div className="w-full p-3 bg-black/40 rounded-lg text-center text-white text-lg">
      {<p>Loading...</p>}
    </div>;
  }

  data?.sort((a, b) => {
    if (moment(a.timestamp).isSame(b.timestamp)) {
      return a.id < b.id ? 1 : -1 // because seconds are not there so we are comparing SQL id
    } else {
      return moment(a.timestamp).isBefore(b.timestamp) ? 1 : -1
    }
  })

  return (
    <>
      <VitalTableCard
          twoValue={true}
          valOne="Sys"
          valTwo="Dia"
          date="Date"
        time="Time"
        isButtonHeading="Edit"
        />
      {data?.map((i) => (
        <VitalTableCard
          twoValue={true}
          valOne={i?.systolic}
          valTwo={i?.diastolic}
          date={formatDate(i?.timestamp)}
          time={formatTime(i?.timestamp)}
          onClick={() => {
            setOpen(true);
            setEntry(i);
          }}
          createdBy={i?.createdBy}
        />
      ))}
      <ReuseableModel open={open} setOpen={setOpen}>
        <BpEditVitalForm setOpen={setOpen} entry={entry} />
      </ReuseableModel>
    </>
  );
}

export default BloodPressureTable;
