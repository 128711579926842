import React from "react";
// import { useState } from "react";
import { useSidebar } from "../context/sidebarcontext";
import { Link } from "react-router-dom";
import "../App.css";
import { useUser } from "../context/UserContext";

const Sidebar = () => {
  const { user } = useUser();
  const { isSidebarOpen } = useSidebar(false);
  const { toggleSidebar } = useSidebar();

  const navigateToCommunityChat = async (event) => {
    event.preventDefault();
    window.location.hash = "#/message";
  };

  return (
    <div className=''>
      {isSidebarOpen ? (
        <div className=''>
          <button
            className='absolute inset-0 h-screen w-screen z-50 md:hidden'
            onClick={toggleSidebar}
          />
          <aside
            className='no-scrollbar absolute z-[99999] left-0 top-0 flex h-screen w-72 flex-col !overflow-y-hidden bg-[#151E3D99]/[95%] lg:bg-black/80 duration-300 ease-linear lg:static'
            style={{ scrollbarWidth: 0, scrollbars: false }}>
            {/* <!-- SIDEBAR OPENED --> */}
            <div className='flex items-center justify-between gap-2 px-6 pt-5 pb-3'>
              <Link to='/'>
                <img
                  src='/images/logo/logo.png'
                  alt='Logo'
                  className='w-44 px-4'
                />
              </Link>

              <button className='block lg:hidden' onClick={toggleSidebar}>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='44'
                  height='44'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <path
                    d='M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10ZM9.17 14.83l5.66-5.66M14.83 14.83 9.17 9.17'
                    stroke='#ffffff'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'></path>
                </svg>
              </button>
            </div>

            {/* <div className="px-6 pt-3 hidden lg:flex">
              <UserAssigneeSidebar />
            </div> */}
            {/* <!-- SIDEBAR HEADER --> */}

            <div className='no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear'>
              {/* <!-- Sidebar Menu --> */}
              <nav className='py-4 px-4 lg:mt-5 lg:px-6'>
                {/* <!-- Menu Group --> */}
                <div>
                  <ul className='mb-6 flex flex-col gap-0.5'>
                    {/* <!-- Menu Item Calendar --> */}
                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/'
                        id='dashboard'>
                        <img
                          src='/icons/Dashboard.svg'
                          alt='Dashboard'
                          className='w-6 h-6'
                        />
                        Dashboard
                      </Link>
                    </button>

                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/healthData'
                        id='Health Data'>
                        <img
                          src='/icons/HealthData.svg'
                          alt='HealthData'
                          className='w-6 h-6'
                        />
                        Health Data
                      </Link>
                    </button>
                    {/* <!-- Menu Item Dashboard --> */}

                    {/* <!-- Menu Item Journal --> */}
                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        id='Journaling'
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/journal'>
                        <img
                          src='/icons/Journalling.svg'
                          alt='Journaling'
                          className='w-5 h-5'
                        />
                        Journaling
                      </Link>
                    </button>
                    {/* <!-- Menu Item QuickJournal --> */}
                    {/* <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        id='QuickJournaling'
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/quick-journal'>
                        <img
                          src='/icons/Journalling.svg'
                          alt='QuickJournaling'
                          className='w-5 h-5'
                        />
                        Quick Journaling
                      </Link>
                    </button> */}
                    {/* <!-- Menu Item Journal --> */}
                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        id='Reminders'
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/reminder'>
                        <img
                          src='/icons/Reminder.svg'
                          alt='Reminders'
                          className='w-5 h-5'
                        />
                        Reminders
                      </Link>
                    </button>

                    {/* <!-- Menu Item Calendar --> */}
                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        id='Calendar'
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/calendar'>
                        <img
                          src='/icons/Reminders.svg'
                          alt='calendar'
                          className='w-5 h-5'
                        />
                        Calendar
                      </Link>
                    </button>

                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        id='Activities'
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/events'>
                        <img
                          src='/icons/Events.svg'
                          alt='Events'
                          className='w-5 h-5'
                        />
                        Activities
                      </Link>
                    </button>

                    {/* <!-- Menu Item Community --> */}
                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        id='Community'
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/community'>
                        <img
                          src='/icons/Community.svg'
                          alt='Community'
                          className='w-6 h-6'
                        />
                        Community
                      </Link>
                    </button>
                    {/* <!-- Menu Item Community --> */}

                    {/* <!-- Menu Item Messaging --> */}

                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        id='Broadcast'
                        onClick={navigateToCommunityChat}
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/message'>
                        <img
                          src='/icons/Broadcast.svg'
                          alt='Broadcast'
                          className='w-6 h-6'
                        />
                        Broadcast
                        {/* <span id="msg-ind-1" class="message-indicator hide bg-indigo-700 p-1 rounded-full">
                          <span class="tooltiptext"></span>
                        </span> */}
                      </Link>
                    </button>
                    {/* <!-- Menu Item Messaging --> */}

                    {/* <!-- Menu Item Contacts --> */}
                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        id='Contacts'
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/contact'>
                        <img
                          src='/icons/Contacts.svg'
                          alt='Contacts'
                          className='w-6 h-6'
                        />
                        Contacts
                      </Link>
                    </button>
                    {/* <!-- Menu Item Contacts --> */}

                    {user?.role === 'Kare Admin' && (
                      <>
                        {' '}
                        {/* <!-- Menu Item Reports --> */}
                        <button
                          onClick={toggleSidebar}
                          disabled={window.innerWidth > 768}>
                          <Link
                            id='Reports'
                            to={'/report'}
                            className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'>
                            <img
                              src='/icons/Reports.svg'
                              alt='Reports'
                              className='w-6 h-6'
                            />
                            Reports
                          </Link>
                        </button>
                        <button
                          id='Analytics'
                          onClick={toggleSidebar}
                          disabled={window.innerWidth > 768}>
                          <div className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'>
                            <img
                              src='/icons/Analytics.svg'
                              alt='Analytics'
                              className='w-6 h-6'
                            />
                            Analytics
                          </div>
                        </button>
                      </>
                    )}

                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        id='Profile'
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/myProfile'>
                        <img
                          src='/icons/Profile.svg'
                          alt='Profile'
                          className='w-6 h-6'
                        />
                        Profile
                      </Link>
                    </button>

                    <button
                      onClick={toggleSidebar}
                      disabled={window.innerWidth > 768}>
                      <Link
                        className='group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-semibold text-xl text-white duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4'
                        to='/allPatients'>
                        <img
                          src='/icons/CareRecipients.svg'
                          alt='CareRecipients'
                          className='w-6 h-6'
                        />
                        Care Recipients
                      </Link>
                    </button>
                    {/* <!-- Menu Item Profile --> */}
                  </ul>
                </div>
              </nav>
              {/* // <!-- Sidebar Menu --> */}
            </div>

            {/* SIDEBAR OPENED  */}
          </aside>
        </div>
      ) : null}
    </div>
  )
};

export default Sidebar;
