import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { logout } from "../HTTPS/ApiService";

const ProtectedRoutes = (props) => {
  const { Component } = props;

  const [redirectUrl, setRedirectUrl] = useState(() => {

    const hasSignedIn = localStorage.getItem("hasSignedIn");
    if (!hasSignedIn) {

      const loginInfo = JSON.parse(localStorage.getItem("user"));
      if (loginInfo) {
        logout(loginInfo.username);
      }

      localStorage.removeItem("hasSignedIn");
      localStorage.clear();
      return "/signin";

    } else {
      const loginInfo = JSON.parse(localStorage.getItem("user"));
      const now = Math.floor(Date.now() / 1000);

      if (loginInfo?.forcePwdChange) {
        return "/reset/password";
      }
      else if (now > loginInfo?.expiry) {

        const loginInfo = JSON.parse(localStorage.getItem("user"));
        if (loginInfo) {
          logout(loginInfo.username);
        }
      
        localStorage.removeItem("hasSignedIn");
        localStorage.clear();

        return "/signin";
      }
      else {
        return "";
      }
    }
  });

  return redirectUrl !== "" ? <Navigate to={redirectUrl} /> : <Component />;
};

export default ProtectedRoutes;
