import * as api from "./HTTPS/VitalApis";
import LineGraphWrapper from "./components/Report/Graphs/LineGraphWrapper";
import ReportTableWrapper from "./components/Report/Graphs/ReportTableWrapper";

export const checkListRaw = [
  {
    label: "Heart Rate",
    category: "Health Vitals",
    api: api.fetchReportHeartRate,
    component: LineGraphWrapper,
  },
  {
    label: "Heart Rate Activities",
    category: "Health Vitals",
    api: api.fetchReportHeartRateObservation,
    component: ReportTableWrapper,
  },
  {
    label: "Blood Pressure",
    category: "Health Vitals",
    api: api.fetchReportBloodPressure,
    component: LineGraphWrapper,
  },
  {
    label: "Blood Pressure Activities",
    category: "Health Vitals",
    api: api.fetchReportBloodPressureObservation,
    component: ReportTableWrapper,
  },
  {
    label: "Sleep",
    category: "Health Vitals",
    api: api.fetchReportSleep,
    component: LineGraphWrapper,
  },
  {
    label: "Sleep Activities",
    category: "Health Vitals",
    api: api.fetchReportSleepObservation,
    component: ReportTableWrapper,
  },
  {
    label: "Weight",
    category: "Health Vitals",
    api: api.fetchReportWeight,
    component: LineGraphWrapper,
  },
  {
    label: "Weight Activities",
    category: "Health Vitals",
    api: api.fetchReportWeightObservation,
    component: ReportTableWrapper,
  },
  {
    label: "Oxygen Level",
    category: "Health Vitals",
    api: api.fetchReportBloodOxygen,
    component: LineGraphWrapper,
  },
  {
    label: "Oxygen Level Activities",
    category: "Health Vitals",
    api: api.fetchReportBloodOxygenObservation,
    component: ReportTableWrapper,
  },
  {
    label: "Breathing Rate",
    category: "Health Vitals",
    api: api.fetchReportBreathrate,
    component: LineGraphWrapper,
  },
  {
    label: "Breathing Rate Activities",
    category: "Health Vitals",
    api: api.fetchReportBreathrateObservation,
    component: ReportTableWrapper,
  },
  {
    label: "Temperature",
    category: "Health Vitals",
    api: api.fetchReportTemperature,
    component: LineGraphWrapper,
  },
  {
    label: "Temperature Activities",
    category: "Health Vitals",
    api: api.fetchReportTemperatureObservation,
    component: ReportTableWrapper,
  },
  {
    label: "Glucose",
    category: "Health Vitals",
    api: api.fetchReportGlucose,
    component: LineGraphWrapper,
  },
  {
    label: "Glucose Activities",
    category: "Health Vitals",
    api: api.fetchReportGlucoseObservation,
    component: ReportTableWrapper,
  },
  {
    label: "Observations",
    category: "Journaling",
    api: api.fetchReportObservation,
    component: ReportTableWrapper,
  },
  {
    label: "Activities",
    category: "Journaling",
    api: api.fetchReportActivity,
    component: ReportTableWrapper,
  },
  {
    label: "Login Data",
    category: "Utilization",
    api: api.fetchReportLoginData,
    component: ReportTableWrapper,
  },
];

