import moment from "moment";
import ApiReturnJson from "../api/ApiReturnJson";
import * as EP from "./Api"; // EP stands for EndPoint
import { signOutUser } from "./ApiService";
import * as PL from "./manualAddVitalPayload"; // PL stands for PayLoad


// Dashboard apis

export const fetchReminderCount = async (recipientId, startDate, endDate) => {
  const formData = { recipientId, startDate, endDate };
  return postData(EP.fetchReminderCountDataEndpoint, formData);
};

export const fetchReminderList = async (recipientId, startDate, endDate) => {
  const formData = { recipientId, startDate, endDate };
  return postData(EP.fetchReminderListDataEndpoint, formData);
};

export const reminderCreateEvent = async (reminderId, eventId) => {
  const formData = { reminderId, eventId };
  return postData(EP.reminderCreateEventDataEndpoint, formData);
};


// report apis Journaling

export const fetchReportObservation = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate };
  return postData(EP.fetchReportNoteEndpoint, formData);
};

export const fetchReportActivity = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate };
  return postData(EP.fetchReportEventEndpoint, formData);
};

export const fetchReportLoginData = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate };
  return postData(EP.fetchReportLoginDataEndpoint, formData);
};

// report apis Health Vitals 
const timezoneOffset = () => ({
  timezoneOffset: moment().utcOffset()*60
})

export const fetchReportBloodPressure = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, ...timezoneOffset() };
  return postData(EP.fetchReportBloodPressureEndpoint, formData);
};

export const fetchReportSleep = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, ...timezoneOffset() };
  return postData(EP.fetchReportSleepEndpoint, formData);
};

export const fetchReportHeartRate = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalName: "heartrate", ...timezoneOffset() };
  return postData(EP.fetchReportOtherVitalEndpoint, formData);
};

export const fetchReportBloodOxygen = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalName: "bloodoxygen", ...timezoneOffset() };
  return postData(EP.fetchReportOtherVitalEndpoint, formData);
};

export const fetchReportGlucose = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalName: "glucose", ...timezoneOffset() };
  return postData(EP.fetchReportOtherVitalEndpoint, formData);
};

export const fetchReportBreathrate = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalName: "breathrate", ...timezoneOffset() };
  return postData(EP.fetchReportOtherVitalEndpoint, formData);
};

export const fetchReportTemperature = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalName: "temperature", ...timezoneOffset() };
  return postData(EP.fetchReportOtherVitalEndpoint, formData);
};

export const fetchReportWeight = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalName: "weight", ...timezoneOffset() };
  return postData(EP.fetchReportOtherVitalEndpoint, formData);
};


// report apis for vital Observation

export const fetchReportHeartRateObservation = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalTypeId: 1 };
  return postData(EP.fetchReportVitalEventEndpoint, formData);
};

export const fetchReportBloodPressureObservation = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalTypeId: 2 };
  return postData(EP.fetchReportVitalEventEndpoint, formData);
};

export const fetchReportSleepObservation = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalTypeId: 3 };
  return postData(EP.fetchReportVitalEventEndpoint, formData);
};

export const fetchReportBloodOxygenObservation = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalTypeId: 4 };
  return postData(EP.fetchReportVitalEventEndpoint, formData);
};

export const fetchReportBreathrateObservation = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalTypeId: 5 };
  return postData(EP.fetchReportVitalEventEndpoint, formData);
};

export const fetchReportTemperatureObservation = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalTypeId: 6 };
  return postData(EP.fetchReportVitalEventEndpoint, formData);
};

export const fetchReportGlucoseObservation = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalTypeId: 7 };
  return postData(EP.fetchReportVitalEventEndpoint, formData);
};

export const fetchReportWeightObservation = (recipientId, fromDate, toDate) => {
  const formData = { recipientId, fromDate, toDate, vitalTypeId: 8 };
  return postData(EP.fetchReportVitalEventEndpoint, formData);
};


// live vital data apis

export const fetchHeartrateVital = async (recipientId) =>
  fetchVitalDataFromApi(EP.fetchHeartrateVitalDataEndpoint, recipientId);

export const fetchBloodPressureVital = async (recipientId) =>
  fetchVitalDataFromApi(EP.fetchBloodPressureVitalDataEndpoint, recipientId);

export const fetchSleepVital = async (recipientId) =>
  fetchVitalDataFromApi(EP.fetchSleepVitalDataEndpoint, recipientId);

export const fetchOxygenVital = async (recipientId) =>
  fetchVitalDataFromApi(EP.fetchOxygenVitalDataEndpoint, recipientId);

export const fetchRespiratoryRateVital = async (recipientId) =>
  fetchVitalDataFromApi(EP.fetchRespiratoryRateVitalDataEndpoint, recipientId);

export const fetchTemperatureVital = async (recipientId) =>
  fetchVitalDataFromApi(EP.fetchTemperatureVitalDataEndpoint, recipientId);

export const fetchGlucoseVital = async (recipientId) =>
  fetchVitalDataFromApi(EP.fetchGlucoseVitalDataEndpoint, recipientId);

export const fetchWeigthVital = async (recipientId) =>
  fetchVitalDataFromApi(EP.fetchWeigthVitalDataEndpoint, recipientId);

export const fetchEventsCount = async (recipientId, startDate, endDate) => {
  const formData = { recipientId, startDate, endDate };
  return postData(EP.fetchEventsCountEndpoint, formData);
};

export const fetchEventsList = async (recipientId, startDate, endDate) => {
  const formData = { recipientId, startDate, endDate };
  return postData(EP.fetchEventsListEndpoint, formData);
};

// Manual edit apis

export const manualAddBloodPressureVital = async (user_id, kare_giver_id, systolic, diastolic, timestamp, timezone_offset) => {
  const formData = PL.buildBloodPressureVitalPayload(user_id, kare_giver_id, systolic, diastolic, timestamp, timezone_offset);
  return postData(EP.postManualAddVitalsDataEndpoint, formData);
};

export const editManualBloodPressureVital = async (user_id, kare_giver_id, systolic, diastolic, timestamp, timezone_offset, id) => {
  const formData = PL.buildBloodPressureVitalPayload(user_id, kare_giver_id, systolic, diastolic, timestamp, timezone_offset, id, "updated");
  return postData(EP.postManualAddVitalsDataEndpoint, formData);
};

export const manualAddTemperatureVital = async (user_id, kare_giver_id, temperature, timestamp, timezone_offset) => {
  const formData = PL.buildTemperatureVitalPayload(user_id, kare_giver_id, temperature, timestamp, timezone_offset);
  return postData(EP.postManualAddVitalsDataEndpoint, formData);
};

export const editManualTemperatureVital = async (user_id, kare_giver_id, temperature, timestamp, timezone_offset = 0, id) => {
  const formData = PL.buildTemperatureVitalPayload(user_id, kare_giver_id, temperature, timestamp, timezone_offset, id, "updated");
  return postData(EP.postManualAddVitalsDataEndpoint, formData);
};

export const manualAddWeightVital = async (user_id, kare_giver_id, weigth, timestamp, timezone_offset) => {
  const formData = PL.buildWeigthVitalPayload(user_id, kare_giver_id, weigth, timestamp, timezone_offset);
  return postData(EP.postManualAddVitalsDataEndpoint, formData);
};

export const editManualWeightVital = async (user_id, kare_giver_id, weigth, timestamp, timezone_offset, id) => {
  const formData = PL.buildWeigthVitalPayload(user_id, kare_giver_id, weigth, timestamp, timezone_offset, id, "updated");
  return postData(EP.postManualAddVitalsDataEndpoint, formData);
};

// listing old manual added data apis

export const listTemperatureVital = async (user_id) => {
  const formData = { user_id, "event_type": "temperature.list" };
  return postData(EP.listVitalEndpoint, formData);
};

export const listBloodPressureVital = async (user_id) => {
  const formData = { user_id, "event_type": "bloodpressure.list" };
  return postData(EP.listVitalEndpoint, formData);
};

export const listWeightVital = async (user_id) => {
  const formData = { user_id, "event_type": "weight.list" };
  return postData(EP.listVitalEndpoint, formData);
};

// Warning!! Functions below are getting used in this file.

async function postData(EndPoint, formData) {
  try {
    const [response] = await Promise.all([
      ApiReturnJson("POST", EndPoint, formData),
    ]);

    if (response.isSuccess) {
      return response.data;
    } else {
      console.error(response);
    }

  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
  }
}

async function fetchVitalDataFromApi(endpoint, recipientId) {
  try {
    const [response] = await Promise.all([
      ApiReturnJson("GET", endpoint + `?id=${recipientId}`),
    ]);

    if (response.isSuccess) {
      return response.data;
    } else {
      console.error(response);
      return [];
    }

  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
  }
}