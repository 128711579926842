import React, { useState } from 'react'
// import { Tooltip } from "@mui/material";
// import GradeOutlinedIcon from "@mui/icons-material/GradeOutlined";
// import GradeIcon from "@mui/icons-material/Grade";
import { usePatient } from '../../context/PatientContext';
import { useFormik } from 'formik';
import { createNoteValidation } from '../../utils/validations';
import { getCurrentLocalDate, getCurrentLocalTime } from '../../utils/chartUtils';

function QuickObservationsAndActivies({
  primaryCategory = ['med', 'exe', 'sleep', 'do', 'don\'t'],
  secondaryCategory = ['I', 'want', 'to', 'feed', 'him'],
  handlePrimaryCardClick = () => {},
  handleSave: mutate = () => { },
  // timestamp = "3:46 am, Wednesday, 19 February 2025",
  isLoading,
  error,
  disabled = false,
  isObservation = true,
}) {
  const { patient } = usePatient();
  const [primaryCategoryText, setPrimaryCategoryText] = useState("")
  
  const formInitialValues = () => ({
      text: "",
      ntDate: getCurrentLocalDate(),
      ntTime: getCurrentLocalTime(),
      isImp: false,
      recipientId: patient?.id, // getting initialize with undefined on page refresh
      file: null,
    })

  const formik = useFormik({
    initialValues: formInitialValues(),
    validationSchema: createNoteValidation(),
    onSubmit: (values, { resetForm }) => {
      mutate(values);
      setPrimaryCategoryText("")
      resetForm({
        values: formInitialValues()
      });
    },
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;
  const isprimaryCategorySelected = primaryCategoryText !== ""
  // let isSecondaryCategorySelected = formik.values.text !== ""

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-col">
        <div>
          <div className='font-bold text-[#1F456E] py-2 sm:py-4 text-2xl sm:text-xl'>Select Category</div>
          <div className="grid grid-cols-2 gap-2 p-2 md:grid-cols-4 md:gap-4">
            {primaryCategory.map((item, i) => (
              <button
                type='button'
                key={i}
                className={`py-5 px-2.5 rounded-full text-lg  ${primaryCategoryText === item.text ? "bg-[#ED7117] text-white" : "text-white bg-[#1F456E]"}`}
                onClick={() => {
                  formik.setFieldValue("text", "")
                  formik.setFieldValue("isImp", false)
                  // isSecondaryCategorySelected = false
                  handlePrimaryCardClick({ categoryId: item.id, recipientId: patient.id });
                  setPrimaryCategoryText(item.text)
                }}
              >
                {item.text}
              </button>
            ))}
          </div>
        </div>

        {isprimaryCategorySelected && (
          <div className="mt-4">
            <div className='font-bold text-[#1F456E] py-2 sm:py-4 text-2xl sm:text-xl'>{isObservation ? "Select Observation" : "Select Activity"}</div>
            <div className="grid grid-cols-2 gap-2 p-2 md:grid-cols-4 md:gap-4">
              {secondaryCategory?.map((item, i) => (
                <button
                  type='button'
                  key={i}
                  className={`py-2 px-4 rounded-lg ${formik.values.text === item.text ? "bg-[#ED7117] text-white" : "bg-[#0492C2] text-white "} text-base`}
                  onClick={() => {
                    formik.setFieldValue("text", item.text);
                    setTimeout(() => {
                      formik.handleSubmit();
                    }, 50);
                  }}
                >
                  {item.text}
                </button>
              ))}
            </div>
          </div>  
        )}
      </div>
      
      {/* {isSecondaryCategorySelected && (
        <div className="flex items-center justify-between mt-4 text-blue-700">
        <Tooltip title="Flag as Important">
          <span
            className="mb-2 flex items-center cursor-pointer"
            onClick={() => formik.setFieldValue("isImp", !formik.values.isImp)}
          >
            {formik.values.isImp ? (
              <GradeIcon className="text-[#ED7117] xsm:!text-4xl" />
            ) : (
              <GradeOutlinedIcon className="text-[#ED7117] text-xl xsm:!text-4xl" />
            )}
          </span> 
        </Tooltip>
        </div>
      )} */}
      
      {/* {isSecondaryCategorySelected && (
        <button
          type='submit'
          className={`w-full mt-4 py-3 rounded-lg font-semibold ${disabled ? 'bg-gray-400 text-gray-200' : 'bg-[#ED7117] text-white'}`}
          disabled={disabled}
        >
          SAVE
        </button>
      )} */}
      
    </form>
  )
}

export default QuickObservationsAndActivies