import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";

import "./App.css";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { useUser } from "./context/UserContext";
import { fetchReminderCount } from "./HTTPS/VitalApis";
import useSevenDayQuery from "./hooks/useSevenDayQuery";
import MessengerService from "./servies/MessengerService";
import { listScheduleDayApi } from "./HTTPS/ApiService";
import {
  convertLocalDateToUTCDateTime
} from "./utils/chartUtils";
import moment from "moment";
import { usePatient } from "./context/PatientContext";

const App = () => {
  const { data: reminderCount } = useSevenDayQuery(
    "ReminderCount",
    fetchReminderCount
  );

  const { data: groupMesageCount } = useQuery({
    queryKey: ["GroupMesageCount"],
    queryFn: () => MessengerService.getGroupMesageCountRevamp(),
    staleTime: 500,
  });

  const { user, userDetails } = useUser();

  const { patient } = usePatient();

  const { data: ScheduleList } = useQuery({
    queryKey: ["ScheduleList", patient?.id, moment().format("YYYY-MM-DD")],
    queryFn: () =>
      listScheduleDayApi(
        patient?.id,
        convertLocalDateToUTCDateTime(moment().format("YYYY-MM-DD")) // present date
      ),
    enabled: !!patient?.id,
  });

  const dashBoardCard = [
    {
      title: "Health Data",
      icon: "./icons/HealthData.svg",
      redirectTo: "/healthData",
    },
    {
      title: "Journaling",
      icon: "./icons/Journalling.svg",
      redirectTo: "/journal",
    },
    {
      title: "Quick Journaling",
      icon: "./icons/Journalling.svg",
      redirectTo: "/quick-journal",
    },
    {
      title: "Reminders",
      icon: "./icons/Reminder.svg",
      redirectTo: "/reminder",
      badge: reminderCount?.value > 0,
      badgeIcon: "./icons/Bell-Icon.svg",
    },
    {
      title: "Calendar",
      icon: "./icons/Reminders.svg",
      redirectTo: "/calendar",
      badge: ScheduleList?.length > 0,
      badgeIcon: "./icons/Bell-Icon.svg",
    },
    {
      title: "Activities",
      icon: "./icons/Events.svg",
      redirectTo: "/events",
    },
    {
      title: "Community",
      icon: "./icons/Community.svg",
      redirectTo: "/community",
    },
    {
      title: "Broadcast",
      icon: "./icons/Broadcast.svg",
      redirectTo: "/message",
      badge: groupMesageCount?.count > 0,
      badgeIcon: "./icons/Bell-Icon.svg",
    },
    {
      title: "Contacts",
      icon: "./icons/Contacts.svg",
      redirectTo: "/contact",
    },
    {
      title: "Reports",
      icon: "./icons/Reports.svg",
      redirectTo: "/report",
      adminOnly: true,
    },
    {
      title: "Analytics",
      icon: "./icons/Analytics.svg",
      adminOnly: true,
    },
    {
      title: "Profile",
      icon: "./icons/Profile.svg",
      redirectTo: "/myprofile",
    },
    {
      title: "Care Recipients",
      icon: "./icons/CareRecipients.svg",
      redirectTo: "/allPatients",
    },
  ];
  const filteredDashboardCard =
    user?.role === "Kare Admin"
      ? dashBoardCard
      : dashBoardCard.filter((item) => !item.adminOnly);

  const array = [userDetails?.fname, userDetails?.lname];
  const name = array.join(" ");
  return (
    <div className="cover ">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">
        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar />
        {/* <!-- ===== Sidebar End ===== --> */}

        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* <!-- ===== Header Start ===== --> */}
          <Navbar />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main className="mt-28 lg:mt-10 bg-gradient-to-b from-[#FAFCFEBF]/70 via-[#FAFCFEBF]/50 to-transparent rounded-t-3xl">
            {/* <Breadcrumb /> */}
            <div className="mx-auto p-2 md:p-6 2xl:p-10">
              {/* <!-- ===== Content Area Start ===== --> */}
              <div className="mb-6 px-2 py-3">
                {/* <UserProfileMenuBar /> */}

                <div className="">
                  <span className="text-[#1D3E7D] text-2xl md:text-4xl font-bold">
                    {"Hello " + name}
                  </span>
                </div>
              </div>

              <div className="flex flex-wrap items-center justify-center gap-5 sm:justify-start">
                {/* <!-- <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5"> --> */}

                <div className="flex flex-wrap justify-center md:justify-start gap-4">
                  {filteredDashboardCard.map((item) => {
                    return (
                      <Link
                        id={item?.title}
                        to={item?.redirectTo}
                        className='bg-white rounded-3xl w-24 sm:w-40 md:w-52 h-32 sm:h-40 p-3 py-5 shadow-[0_10px_10px_rgb(0,0,0,0.2)] flex flex-col space-y-3 justify-center items-center text-center relative'>
                        {item?.badge && (
                          <img
                            src={item?.badgeIcon}
                            alt={'Badge'}
                            className='w-5 h-5 absolute right-2 -top-2'
                          />
                        )}

                        <h2 className='font-bold sm:text-2xl text-[#4a4a68] leading-5'>
                          {item?.title}
                        </h2>
                        <img
                          src={item?.icon}
                          alt={item?.title}
                          className='w-10 h-10 sm:w-16 sm:h-16'
                        />
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
};

export default App;
