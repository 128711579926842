import {
  loginEndpoint,
  logoutEndpoint,
  resetPasswordEndpoint,
  userDetailsEndpoint,
  patientDetailsEndpoint,
  patientListEndpoint,
  createNoteEndpoint,
  listNoteEndpoint,
  createEventEndpoint,
  listEventEndpoint,
  fetchContactsEndpoint,
  fetchCommunityEndpoint,
  fetchEventsCategoriesEndpoint,
  fetchRelationshipsEndpoint,
  EditNoteEndpoint,
  DeleteNoteEndpoint,
  EditEventEndpoint,
  DeleteEventEndpoint,
  EditProfileEndpoint,
  DeleteVitalEventEndpoint,
  EditVitalEventEndpoint,
  createVitalEventEndpoint,
  listVitalEventEndpoint,
  listVitalTypeEndpoint,
  UploadPatientProfileImageEndpoint,
  EditMyProfileEndpoint,
  UploadMyProfileImageEndpoint,
  CalendarScheduleCreateEndpoint,
  CalendarScheduleListDayEndpoint,
  CalendarScheduleListMonthEndpoint,
  CalendarScheduleDeleteEndpoint,
  ToggleNoteEndpoint,
  ToggleEventEndpoint,
  ToggleVitalEventEndpoint,
  CalendarScheduleEditEndpoint,
  CalendarScheduleSelectEndpoint,
  fetchActivityCategoriesEndpoint,
  fetchObservationCategoriesEndpoint,
  ActivitySubCategoriesEndpoint,
  ObservationSubCategoriesEndpoint,
} from "./Api";
import ApiReturnJson from "../api/ApiReturnJson";
import MessengerService from "../servies/MessengerService";

// Function to sign out the user
export async function signOutUser() {

  const loginInfo = JSON.parse(localStorage.getItem("user"));
  if (loginInfo) {
    await logout(loginInfo.username);
  }

  MessengerService.dispose();
  localStorage.removeItem("hasSignedIn");
  localStorage.clear();
  //window.location.reload(true);
  window.location.href = "/";
}

// __________POST APIS___________

// Log in user with provided credentials
export async function loginUser(username, password) {
  try {
    const response = await fetch(loginEndpoint, {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });

    if (response.ok) {
      return response.json();
    } else {
      throw new Error(
        "Invalid credentials. Please check your email or password."
      );
    }
  } catch (error) {
    throw new Error(
      "An error occurred while signing in. Please try again later."
    );
  }
}

export async function logout(username) {
  try {
    await fetch(logoutEndpoint, {
      method: "POST",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username }),
    });
  } catch (error) {
    //do nothing;
  }
}

// Log in user with provided credentials
export async function resetPasswordUser(currentPassword, newPassword) {
  try {
    var formData = { oldPassword: currentPassword, newPassword: newPassword };

    const [obj] = await Promise.all([
      ApiReturnJson("POST", resetPasswordEndpoint, formData),
    ]);

    let data = [];
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
    }
    return data;
  } catch (error) {
    console.log(
      `Error occurred while resetting your password: ${error.message}`
    );
    throw error; // rethrow the error to maintain consistent error handling
  }
}

// Fetch details of the user with the provided access token
// TODO make it based on ApiReturnJson
export async function fetchUserDetails() {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", userDetailsEndpoint, {}),
    ]);

    let data = [];
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error occurred while fetching user details:", obj);
    }
    return data;
  } catch (error) {
    console.log(
      `Error occurred while fetching user details: ${error.message}`
    );
    throw error; // rethrow the error to maintain consistent error handling
  }
}

// --------- FOR PATIENTS ---------
// Profile Edit Api
export const EditProfileApi = async (profileData) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", EditProfileEndpoint, profileData),
    ]);

    let data = [];
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error occurred while updating user details:", obj);
    }
    return data;
  } catch (error) {
    console.log(
      `Error occurred while updating user details: ${error.message}`
    );
    throw error; // rethrow the error to maintain consistent error handling
  }
};

export const UploadPatientProfileImageApi = async (data) => {
  try {
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("file", data.file);

    const [response] = await Promise.all([
      ApiReturnJson(
        "POST-FORMDATA",
        UploadPatientProfileImageEndpoint,
        formData
      ),
    ]);

    if (response != null && response.isSuccess) {
      return response.data;
    }

    if (response.status === 200) {
      return response.data; // Assuming the API returns the created note
    }
  } catch (error) {
    console.error("Failed to Upload Image", error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error;
  }
};
// --------- FOR PATIENTS ---------

// --------- LOGGED IN USER ---------
// Profile Edit Api
export const EditMyProfileApi = async (profileData) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", EditMyProfileEndpoint, profileData),
    ]);

    let data = [];
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error occurred while editing profile:", obj);
    }
    return data;
  } catch (error) {
    console.log(
      `Error occurred while editing profile: ${error.message}`
    );
    throw error; // rethrow the error to maintain consistent error handling
  }
};

export const UploadMyProfileImageApi = async (data) => {
  try {
    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("file", data.file);

    const [response] = await Promise.all([
      ApiReturnJson("POST-FORMDATA", UploadMyProfileImageEndpoint, formData),
    ]);
    console.log("Upload API Response:", response);

    if (response != null && response.isSuccess) {
      return response.data;
    }

    if (response.status === 200) {
      return response.data; // Assuming the API returns the created note
    }
  } catch (error) {
    console.error("Failed to Upload Image", error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error;
  }
};

// --------- LOGGED IN USER ---------

// Fetch VitalType API
export const fetchVitalType = async () => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", listVitalTypeEndpoint, {}),
    ]);

    let data = [];
    if (obj.isSuccess) {
      data = obj.data;
    }

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};

// Function to delete an event
export const deleteVitalEventApi = async (eventId) => {
  try {
    // var formData = { eventId: eventId, accessToken: CachedUser()?.accessToken };

    const [obj] = await Promise.all([
      ApiReturnJson("POST", DeleteVitalEventEndpoint, eventId),
    ]);

    let data = [];
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
      return Promise.reject(obj.data);
    }
    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    console.log(`Error occurred while Deleting Note: ${error.message}`);
    throw error; // rethrow the error to maintain consistent error handling
  }
};

// Function to edit an event
export const editVitalEventApi = async (eventData) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", EditVitalEventEndpoint, eventData),
    ]);

    let data = {};
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
      return Promise.reject(obj.data);
    }

    return data;
  } catch (error) {
    console.error("Failed to edit event:", error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error;
  }
};

// Function to edit an event
export const editEventApi = async (eventData) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", EditEventEndpoint, eventData),
    ]);

    let data = {};
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
    }

    return data;
  } catch (error) {
    console.error("Failed to edit event:", error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error;
  }
};

// Function to delete an event
export const deleteEventApi = async (eventId) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", DeleteEventEndpoint, eventId),
    ]);

    if (obj.isSuccess) {
      return obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
      return Promise.reject(obj);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw new Error(`Error occurred while Deleting Note: ${error.message}`);
  }
};

export const listVitalEventsApi = async (recipientId, vitalTypeId) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", listVitalEventEndpoint, {
        recipientId,
        vitalTypeId,
      }),
    ]);

    let data = [];
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
    }

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log(error.status);
      signOutUser();
    }
    console.error("Failed to fetch events:", error);
    throw error;
  }
};

export const createVitalEventApi = async (eventData) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", createVitalEventEndpoint, eventData),
    ]);

    let data = {};
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
      return Promise.reject(obj.data);
    }

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw new Error(
      `Error occurred while making the API call: ${error.message}`
    );
  }
};

export const listNotesApi = async (recipientId) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", listNoteEndpoint, { recipientId: recipientId }),
    ]);

    let notes = [];
    if (obj.isSuccess) {
      notes = obj.data;
    }
    return notes;
  } catch (error) {
    console.error("Failed to list notes", error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error;
  }
};

export const editNoteApi = async (noteData) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", EditNoteEndpoint, noteData),
    ]);

    let data = {};
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
    }

    return data;
  } catch (error) {
    console.error("Failed to edit note:", error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error;
  }
};

export const DeleteNoteApi = async (noteId) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", DeleteNoteEndpoint, noteId),
    ]);

    let data = {};
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
    }

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw new Error(`Error occurred while Deleting Note: ${error.message}`);
  }
};

export const toggleNote = async (id, isImp) => {
  console.log(id, isImp);
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", ToggleNoteEndpoint, { id, isImp }),
    ]);

    let data = {};
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
    }

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw new Error(
      `Error occurred while Toggling Important Observation: ${error.message}`
    );
  }
};

export const toggleEvent = async (id, isImp) => {
  console.log(id, isImp);
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", ToggleEventEndpoint, { id, isImp }),
    ]);

    let data = {};
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
    }

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw new Error(
      `Error occurred while Toggling Important Activity: ${error.message}`
    );
  }
};

export const toggleVitalEvent = async (id, isImp) => {
  console.log(id, isImp);
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", ToggleVitalEventEndpoint, { id, isImp }),
    ]);

    let data = {};
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
    }

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw new Error(
      `Error occurred while Toggling Important Activity: ${error.message}`
    );
  }
};

export const listEventsApi = async (recipientId) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", listEventEndpoint, { recipientId: recipientId }),
    ]);

    let events = [];
    if (obj.isSuccess) {
      events = obj.data;
    }
    return events;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log(error.status);
      signOutUser();
    }
    console.error("Failed to fetch events:", error);
    throw error;
  }
};

export const createEventApi = async (eventData) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", createEventEndpoint, eventData),
    ]);

    let events = [];
    if (obj.isSuccess) {
      events = obj.data;
    }
    return events;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log(error.status);
      signOutUser();
    }
    console.error("Failed to create event:", error);
    throw error;
  }
};

// Multipart Form Post
export const createNoteApi = async (noteData) => {
  try {
    const formData = new FormData();
    formData.append("text", noteData.text);
    formData.append("ntDate", noteData.ntDate);
    formData.append("ntTime", noteData.ntTime);
    formData.append("isImp", noteData.isImp);
    formData.append("recipientId", noteData.recipientId);
    formData.append("file", noteData.file);

    const [response] = await Promise.all([
      ApiReturnJson("POST-FORMDATA", createNoteEndpoint, formData),
    ]);

    if (response != null && response.isSuccess) {
      return response.data;
    } else {
      return Promise.reject(response);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log(error.status);
      signOutUser();
    }
    console.error("Failed to create Note:", error);
    throw error;
  }
};

export const CalendarScheduleCreateApi = async (scheduleData) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", CalendarScheduleCreateEndpoint, scheduleData),
    ]);

    if (obj.isSuccess) {
      return obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
      return Promise.reject(obj);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw new Error(
      `Error occurred while making the API call: ${error.message}`
    );
  }
};

export const CalendarScheduleEditApi = async (scheduleData) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", CalendarScheduleEditEndpoint, scheduleData),
    ]);

    if (obj.isSuccess) {
      return obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
      return Promise.reject(obj);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw new Error(
      `Error occurred while making the API call: ${error.message}`
    );
  }
};

export const CalendarScheduleSelectApi = async (scheduleId) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", CalendarScheduleSelectEndpoint, scheduleId),
    ]);

    if (obj.isSuccess) {
      return obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
      return Promise.reject(obj);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw new Error(
      `Error occurred while making the API call: ${error.message}`
    );
  }
};

// DeleteSchedule Api
export const CalendarScheduleDeleteApi = async (scheduleId) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", CalendarScheduleDeleteEndpoint, scheduleId),
    ]);

    let data = {};
    if (obj.isSuccess) {
      data = obj.data;
    } else {
      console.log("Error in ApiReturnJson:", obj);
    }

    return data;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw new Error(`Error occurred while Deleting Note: ${error.message}`);
  }
};

export const listScheduleDayApi = async (recipientId, startDate, endDate) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", CalendarScheduleListDayEndpoint, {
        startDate,
        endDate,
        recipientId,
      }),
    ]);

    if (obj.isSuccess) {
      return obj.data;
    } else {
      console.error(obj);
      return Promise.reject(obj);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log(error.status);
      signOutUser();
    }
    console.error("Failed to fetch events:", error);
    throw error;
  }
};

export const listScheduleMonthApi = async (recipientId, startDate, endDate) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", CalendarScheduleListMonthEndpoint, {
        startDate,
        endDate,
        recipientId,
      }),
    ]);

    if (obj.isSuccess) {
      return obj.data;
    } else {
      console.error(obj);
      return Promise.reject(obj);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log(error.status);
      signOutUser();
    }
    console.error("Failed to fetch events:", error);
    throw error;
  }
};

export const listActivitySubCategoriesApi = async (categoryId, recipientId) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", ActivitySubCategoriesEndpoint, {
        categoryId,
        recipientId,
      }),
    ]);

    if (obj.isSuccess) {
      return obj.data;
    } else {
      console.error(obj);
      return Promise.reject(obj);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log(error.status);
      signOutUser();
    }
    console.error("Failed to fetch events:", error);
    throw error;
  }
};

export const listObservationSubCategoriesApi = async (categoryId, recipientId) => {
  try {
    const [obj] = await Promise.all([
      ApiReturnJson("POST", ObservationSubCategoriesEndpoint, {
        categoryId,
        recipientId,
      }),
    ]);

    if (obj.isSuccess) {
      return obj.data;
    } else {
      console.error(obj);
      return Promise.reject(obj);
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      console.log(error.status);
      signOutUser();
    }
    console.error("Failed to fetch events:", error);
    throw error;
  }
};

// __________GET APIS___________
export const getPatientDetails = async (recipientId) => {
  try {
    const [response] = await Promise.all([
      ApiReturnJson("GET", patientDetailsEndpoint + `?id=${recipientId}`),
    ]);

    if (response.isSuccess) {
      return response.data;
    } else {
      console.error(response);
      return [];
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error; // how to handle the error properly insted of throwing it?
  }
};

// fetching the lsit of patients which are assigned to a care-giver
// WARNING!!! this api is creating a lot of errors
export const fetchPatientList = async () => {
  if (JSON.parse(localStorage.getItem("user"))) {
    try {
      const [response] = await Promise.all([
        ApiReturnJson("GET", patientListEndpoint),
      ]);

      if (response.isSuccess) {
        return response.data;
      } else {
        console.error(response);
        return [];
      }
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        signOutUser();
      }
      throw error; // how to handle the error properly insted of throwing it?
    }

  }
};

export const fetchContacts = async (recipientId) => {
  const [contacts] = await Promise.all([
    ApiReturnJson("GET", fetchContactsEndpoint + `?recipientId=${recipientId}`),
  ]);

  var lst = [];
  if (contacts.isSuccess) {
    lst = contacts.data;
  }
  return lst;
};

export const fetchEventsCategories = async () => {
  try {
    const [response] = await Promise.all([
      ApiReturnJson("GET", fetchEventsCategoriesEndpoint),
    ]);

    if (response.isSuccess) {
      return response.data;
    } else {
      console.error(response);
      return Promise.reject(response);
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error; // how to handle the error properly insted of throwing it?
  }
};

export const fetchRelationships = async (recipientId) => {
  try {
    const [response] = await Promise.all([
      ApiReturnJson("GET", fetchRelationshipsEndpoint + `?recipientId=${recipientId}`
      ),
    ]);

    if (response.isSuccess) {
      return response.data;
    } else {
      console.error(response);
      return [];
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error; // how to handle the error properly insted of throwing it?
  }
};

export const fetchActivityCategories = async () => {
  try {
    const [response] = await Promise.all([
      ApiReturnJson("GET", fetchActivityCategoriesEndpoint),
    ]);

    if (response.isSuccess) {
      return response.data;
    } else {
      console.error(response);
      return [];
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error; // how to handle the error properly insted of throwing it?
  }
};

export const fetchObservationCategories = async () => {
  try {
    const [response] = await Promise.all([
      ApiReturnJson("GET", fetchObservationCategoriesEndpoint),
    ]);

    if (response.isSuccess) {
      return response.data;
    } else {
      console.error(response);
      return [];
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response.status === 401) {
      signOutUser();
    }
    throw error; // how to handle the error properly insted of throwing it?
  }
};

export const fetchCommunity = async (recipientId) => {
  try {
    const [response] = await Promise.all([
      ApiReturnJson("GET", fetchCommunityEndpoint + `?recipientId=${recipientId}`),
    ]);

    if (response.isSuccess) {
      return response.data;
    } else {
      console.error(response);
      return [];
    }
  } catch (error) {
    console.log(error);
    if (error.response && error.response === 401) {
      signOutUser();
    }
    throw error; // how to handle the error properly insted of throwing it?
  }
};