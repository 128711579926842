import React from "react";
import EditIcon from "../Icons/EditIcon";
import { useUser } from "../../context/UserContext";

function VitalTableCard({
  twoValue = false,
  onClick = () => {},
  valOne,
  valTwo,
  date,
  time,
  createdBy = "",
  isButtonHeading = null,
}) {
  const { user, userDetails } = useUser();

  return (
    <div className="bg-white py-2.5 px-3 mb-2 rounded-lg flex justify-between items-center max-w-lg xl:max-w-xl mx-auto gap-y-2 xl:gap-y-0 flex-wrap">
      <div className={`flex-1 grid ${twoValue ? "grid-cols-6" : "grid-cols-5"} justify-items-stretch`}>
      <span className="text-customBlue col-span-2">{date}</span>
        <span className="text-customBlue justify-self-start">{time}</span>
        <span className="flex justify-right text-xl font-bold text-customBlue justify-self-center">{valOne}</span>
        {twoValue && (
          <span className="flex justify-right text-xl font-bold text-customBlue justify-self-end">{valTwo}</span>
        )}
        {isButtonHeading
          ? null
          :  <button
          disabled={
            user?.role === "Viewer" ||
            (user?.role === "Kare Giver" && userDetails?.id !== createdBy)
          }
          className={`${
            user?.role === "Viewer" ||
            (user?.role === "Kare Giver" && userDetails?.id !== createdBy)
              ? "opacity-30"
              : ""
          } justify-self-end`}
          onClick={onClick}
        >
          <EditIcon />
        </button>}
      </div>
    </div>
  );
}

export default VitalTableCard;
