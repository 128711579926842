import React from "react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";

import QuickObservationsAndActivies from "./QuickObservationsAndActivies";
import {
  createEventApi,
  createNoteApi,
  fetchActivityCategories,
  fetchObservationCategories,
  listActivitySubCategoriesApi,
  listObservationSubCategoriesApi,
} from "../../HTTPS/ApiService";
import { useToastContext } from "../../context/ToastContext";
import { usePatient } from "../../context/PatientContext";
import {
  convertLocalDateTimeToUTC,
  convertLocalDateToUTC,
} from "../../utils/chartUtils";
import { activeTabState } from "../../recoil/journel/activeTabSate";

const QuickJournalingComp = ({
  fetchEvents = () => {},
  setQuickJournalingOpen = () => {},
}) => {
  const [active, setActive] = useRecoilState(activeTabState);
  const [observationSubCat, setObservationSubCat] = useState(null);
  const [activitySubCat, setActivitySubCat] = useState(null);
  const queryClient = useQueryClient();

  const { setOpenSuccessToast, setOpenErrorSuccessToast, setToastMessage } =
    useToastContext();
  const { patient } = usePatient();

  const toggleActive = (index) => {
    setActive(index);
  };

  const {
    data: observationCategories,
    isLoading: isObservationLoading,
    error: observationError,
  } = useQuery({
    queryKey: ["observationCategories"],
    queryFn: fetchObservationCategories,
  });

  const {
    data: activityCategories,
    isLoading: isActivityLoading,
    error: activityError,
  } = useQuery({
    queryKey: ["activityCategories"],
    queryFn: fetchActivityCategories,
  });

  const { mutate: mutateObservationSubCat } = useMutation({
    mutationFn: ({ categoryId, recipientId }) =>
      listObservationSubCategoriesApi(categoryId, recipientId),
    onSuccess: (data) => {
      setObservationSubCat(data);
    },
    onError: () => {},
  });

  const { mutate: mutateActivitySubCat } = useMutation({
    mutationFn: ({ categoryId, recipientId }) =>
      listActivitySubCategoriesApi(categoryId, recipientId),
    onSuccess: (data) => {
      setActivitySubCat(data);
    },
    onError: () => {},
  });

  const { mutate: mutateObservation, isPending: isPendingObservation } =
    useMutation({
      mutationFn: async (arg) => {
        if (patient?.id) {
          return await createNoteApi({
            ...arg,
            ntDate: convertLocalDateToUTC(arg.ntDate, arg.ntTime),
            ntTime: convertLocalDateTimeToUTC(arg.ntTime),
            recipientId: patient?.id, // to handle page refresh issue
          });
        } else {
          Promise.reject();
        }
      },
      onSuccess: (_, b) => {
        setToastMessage("Observation Created Successfully");
        setOpenSuccessToast(true);
        queryClient.invalidateQueries(["Observation", patient?.id]);
      },
      onError: () => {
        setToastMessage("Failed to create observation");
        setOpenErrorSuccessToast(true);
      },
    });

  const { mutate: mutateActivity, isPending: isPendingActivity } = useMutation({
    mutationFn: async (arg) =>
      await createEventApi({
        ...arg,
        evDate: convertLocalDateToUTC(arg.ntDate, arg.ntTime),
        evTime: convertLocalDateTimeToUTC(arg.ntTime),
      }),
    onSuccess: () => {
      fetchEvents();
      setToastMessage("Activity Created Successfully");
      setOpenSuccessToast(true);
    },
    onError: () => {
      setToastMessage("Failed to create activity");
      setOpenErrorSuccessToast(true);
    },
  });

  return (
    <>
      <h2 className="text-3xl sm:text-5xl font-bold text-[#1D3E7D]">
        Quick Journaling
      </h2>

      {/* Toggle Button  */}
      <div className="flex flex-row gap-3 sm:gap-5 justify-around items-center py-3">
        <button
          onClick={() => {
            toggleActive(1);
          }}
          className={`font-bold text-[#1F456E] border-b-4 sm:border-b-8 border-transparent w-1/2 rounded-t-2xl py-2 sm:py-4 text-lg sm:text-2xl ${
            active === 1 ? "!border-[#1F456E] bg-[#ECF1F4] px-2" : ""
          }`}
          setActive={true}
        >
          Observations
        </button>
        <button
          onClick={() => {
            toggleActive(2);
          }}
          className={`font-bold text-[#1F456E] border-b-4 sm:border-b-8 border-transparent w-1/2 rounded-t-2xl py-2 sm:py-4 text-lg sm:text-2xl ${
            active === 2 ? "!border-[#1F456E] bg-[#ECF1F4]" : ""
          }`}
        >
          Activities
        </button>
      </div>

      {/* Observations tab */}
      {active === 1 && (
        <QuickObservationsAndActivies
          primaryCategory={observationCategories}
          handlePrimaryCardClick={mutateObservationSubCat}
          secondaryCategory={observationSubCat}
          handleSave={(value) => {
            mutateObservation(value);
            setObservationSubCat(null);
            setQuickJournalingOpen(false);
          }}
          disabled={isPendingObservation}
          isLoading={isObservationLoading}
          error={observationError}
        />
      )}

      {/* Activies Tab  */}
      {active === 2 && (
        <QuickObservationsAndActivies
          primaryCategory={activityCategories}
          handlePrimaryCardClick={mutateActivitySubCat}
          secondaryCategory={activitySubCat}
          handleSave={(value) => {
            mutateActivity(value);
            setActivitySubCat(null);
            setQuickJournalingOpen(false);
          }}
          disabled={isPendingActivity}
          isLoading={isActivityLoading}
          error={activityError}
          isObservation={false}
        />
      )}
    </>
  );
};

export default QuickJournalingComp;
