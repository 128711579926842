import QuickJournalingComp from "../components/Blocks/QuickJournalingComp";
import MainAreaWrapper from "../layout/MainAreaWrapper";

const QuickJournal = () => {
  

  return (
    <MainAreaWrapper >
      <main className="bg-white mt-28 lg:mt-10 rounded-t-3xl pb-10 lg:pb-0 -z-0">
        <div className="mx-auto p-4 md:p-6 2xl:p-10">
          <div className="mx-auto">
            <QuickJournalingComp />
          </div>
        </div>
      </main>
    </MainAreaWrapper>
  );
};

export default QuickJournal;
